<template>
  <content-with-sidebar
    class="cws-container cws-sidebar-right blog-wrapper show-ticket-wrapper"
  >
    <!-- content -->
    <div
      v-if="message"
      class="blog-detail-wrapper"
    >
      <b-row>
        <b-col cols="12">
          <b-card
            img-top
            :title="message.msg_subject"
          >
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <b-avatar
                  size="24"
                  :src="message.author_avatar"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-50">by</small>
                <small>
                  <b-link
                    v-if="message.author_is_admin"
                    class="text-body"
                  >TrafficManager Support</b-link>
                  <router-link
                    v-else-if="user.userRole === 'Admin'"
                    :to="`/user/details/${ticket.uid}`"
                    class="text-body"
                  >{{ message.author_email }}</router-link>
                  <b-link
                    v-else
                    class="text-body"
                  >{{ message.author_email }}</b-link>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ message.created_at }}</small>
                <small
                  v-if="assignedTo.forename.forename"
                  style="margin-right: 10px;"
                >&nbsp;&nbsp;&nbsp; Assigned To - {{ assignedTo.forename.forename }}  {{ assignedTo.forename.surname }}</small>
              </b-media-body>
            </b-media>

            <div
              class="my-1 py-25"
            >
              <b-badge
                pill
                class="mr-75"
                :variant="'light-primary'"
              >
                System message
              </b-badge>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="message.author_is_admin"
              class="blog-content mb-2"
              v-html="message.msg_text"
            />
            <!--eslint-enable-->
            <div
              v-else
              class="blog-content mb-2"
            >{{ message.msg_text }}
            </div>

            <hr class="my-2">
          </b-card>
        </b-col>
      </b-row>
    </div>
    <!--/ content -->
  </content-with-sidebar>
</template>

<script>
import {
  BMedia, BAvatar, BMediaAside, BMediaBody, BLink,
  BCard, BRow, BCol, VBTooltip, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@/libs/axios'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'

export default {
  components: {
    BBadge,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      search_query: '',
      commentCheckmark: '',
      ticket: '',
      blogSidebar: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      sending: false,
      closing: false,
      category: null,
      categories: [],
      status: null,
      statuses: [],
      admins: [],
      message: null,
      assignedTo: {
        uid: null,
        forename: 'Nobody',
      },
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
  },
  watch: {
    $route(to) {
      this.message = null
      this.$store.dispatch('messages/fetchMessage', to.params.id)
        .then(res => {
          this.message = res.data
        })
    },
  },
  created() {
    this.fetchMessage()
  },
  methods: {
    fetchMessage() {
      this.$store.dispatch('messages/fetchMessage', this.$route.params.id)
        .then(res => {
          this.message = res.data
          this.fetchTicket()
        })
    },
    fetchTicket() {
      axios.get(`/api/tickets/${this.message.ticket_id}`)
        .then(res => {
          this.ticket = res.data.data
          if (res.data.data.assignedTo) {
            this.assignedTo.forename = res.data.data.assignedTo
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
.show-ticket-wrapper .ql-editor {
  min-height: 320px;
}
</style>
